body {
  background-color: #253330; 
  color: #c9d3cf;
  font-family: 'Oswald', sans-serif;
}

#sidebar-wrapper {
  width: 20%;
}

.navbar {
  display: none;
}

.logo-container {
  background-image: url('../public/Assets/Logo.png');
  background-size: cover; 
  background-repeat: no-repeat;
  height: 50px;
  width: 50px;
  padding-right: 50px;
}

.navbar-brand {
  padding-left: 50px;
}

.navbar-toggler {
  padding-right: 50px;
}

h1, h3, h4, h5, h6 {
  color: #c3cdc9;
  font-family: 'Oswald', sans-serif;
}

h2 {
  color: #ffffff;
  font-family: 'Oswald', sans-serif;
}

a, .btn {
  background-color: #94270200;
  color: #F2F2F2;
}


#main {
  flex-grow: 1;
  max-width: 1200px;
  padding-left: 200px;
}

#home {
  width: 100%;
  text-align: center;
  align-self: center;
}

.welcome {
  margin-top: -20vh;
  text-align: left;
}


header, footer {
  background-color: #025942;
}


#about {
  padding-left: 30px;
}

.floating-image {
  float: right; 
  max-width: 55%;
  height: auto;
  margin: 10px 26px 16px 0;
}

#skills {
  margin-left: 5vw;
}

.icon {
  color: #c9d3cf;
  font-size: 2rem;
  transition: color 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.icon:hover {
  color: #942702;
  transform: scale(1.2);
}

.icon:active {
  transform: scale(0.9);
}

#projects h2 {
  margin-left: 5vw;
}

.project-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.project-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}

.project-logo {
  max-width: 200px; /* Adjust width to fit */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 15px;
  border-radius: 8px; /* Optional: adds rounded corners */
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

.project-info {
  background-color: rgba(0, 0, 0, 0.441);
  text-shadow: 1px 1px 1px rgb(31, 80, 53);
  border-radius: 10px;
  padding: 2% 5%;
  margin-top: 15px;
  max-width: 600px; /* Limit width for better readability */
}

.project-info h3 {
  font-size: 1.5rem; /* Adjust heading size */
}

.project-info p {
  font-size: 1rem;
  line-height: 1.6;
  margin: 10px 0;
}

#modal-background {
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.818);
  text-shadow: 1px 1px 1px rgb(31, 80, 53);
}

.modal_body {
  background-color: rgba(7, 97, 86, 0.931);
}

.modal_header {
  background-color: grey;
}

.modal_footer {
  background-color: grey;
}

.modal_button {
  background-color: rgb(45, 44, 44);
}


.resume-button {
  margin-bottom: 15px;
}

#experience {
  padding-left: 4%;
  padding-right: 35%;
  max-width: 1200px; /* Optional: Set a max-width if you don't want it to stretch too wide */
  margin: 0 auto; /* Center the content */
}


#experience h4 {
  font-size: 27px;
}

#contact {
  justify-content: center;
  width: 115.5%;
  padding: 1.4% 0;
  background-color: rgba(4, 75, 71, 0.745);
}

.contact-section {
  width: 80%;
  padding: 1.1% 4%;
}

#contact h2 {
  padding: 1% 0 0 3.9%;
  margin-bottom: -.5rem;
}

.contact-info {
  display: flex;
}

.contact-item {
  margin: 1rem 0;
  display: flex;
  align-items: center;
}

.contact-item .fa {
  margin-right: 0.5rem;
  font-size: 1.5em;
}

.contact-item a {
  margin-left: 5px;
  color: white;
  text-decoration: none;
}

.contact-item a:hover {
  text-decoration: underline;
}

.midjourney img {
  max-width: 9%;
  margin: -1%;
}

.midjourney {
  margin-left: -1%;
}


@media (max-width: 992px) {
  .navbar {
    display: block;
  }
  
  #main {
    padding-left: 0;
  }

  #skills {
    margin-left: 1vw;
  }

  #projects h2 {
    margin-left: 1vw;
  }
  
  .project-carousel {
    margin-top: 30px;
    margin-left: 1vw;
  }

  .project-carousel .d-block {
    width: 100%;
    object-fit: contain;
    height: 850px;
  }

  .carousel-item {
    overflow: hidden; 
  }
  
  .carousel-item img {
    margin: -290px 0;
    object-fit: scale-down; 
  }

  .project-carousel .carousel-indicators {
    bottom: 280px; 
  }

  .project_info {
    width: 90%;
    margin: 0 5%;
    margin-top: 50px;
  }

  .project-carousel .carousel-item {
    height: 600px;
  }


  #contact {
    width: 100vw;
    background-color: rgba(4, 75, 71, 0.745);
  }
  
  .contact-section {
    width: 100%;
    padding: 2% 2%;
  }
  
  #contact h2 {
    padding: 20px 0 0 0;
    font-size: 1.6rem;
  }
  
  .contact-info {
    display: flex;
    flex-wrap: wrap;
  }
  
  .contact-item {
    margin: 1rem 0;
    display: flex;
    width: 40%;
    align-items: center;
  }
  
  .contact-item .fa {
    margin-right: 0.5rem;
    font-size: .8em;
  }
  
  .contact-item a {
    margin-left: 5px;
    color: white;
    text-decoration: none;
  }

  .midjourney img {
    max-width: 16%;
    margin: -3px;
  }
  
  .contact-item a:hover {
    text-decoration: underline;
  }

  .contact-left{
    margin-bottom: -4%;
  }

}
