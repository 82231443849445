.list-group-item {
    background-color: #6c757d;
    margin-bottom: 3px
  }
  
  .d-flex {
    display: flex;
  }
  
  #sidebar-wrapper {
    width: 200px;
    height: 100%;
    overflow-y: auto;
    background-color: #343a40;
    position: fixed;
  }
  #sidebar-top {
    text-align: center;
    background-color: black;
    margin-bottom: -30px;
  }
  
  #sidebar-wrapper .sidebar-heading,
  #sidebar-wrapper .list-group-item {
    text-align: center;
    width: 200px;
    border-bottom: none;
  }